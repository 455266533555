<template>
    <hb-expansion-panel key="apw_setting">
          <template v-slot:title> Advance Reservation and Rental </template>
          <template v-slot:content>
            <hb-form label="Space Grouping Profile" required>
                <hb-select
                    v-model="form.spaceGroupProfile"
                    name="space_group"
                    data-vv-as="space grouping profile"
                    placeholder="Select Space Grouping Profile"
                    :items="spaceGroups"
                    item-text="name"
                    :item-value="
                    (value) => {
                        return { id: value.id, name: value.name };
                    }
                    "
                    :clearable="false"
                >
                </hb-select>
            </hb-form>
          </template>
          <template v-slot:footer>
            <hb-bottom-action-bar :top-border="false">
                <template v-slot:right-actions>
                    <hb-btn @click="saveSetting" >Save</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </template>
    </hb-expansion-panel>
</template>

<script>
import api from '../../../assets/api';
import { notificationMixin } from '../../../mixins/notificationMixin';
export default {
    data() {
        return {
            spaceGroups: [],
            form:{
                spaceGroupProfile:{},
                instoreCutoffTime: '',
                onlineCutoffTime: '',
            },
            previousSpaceGroupProfile:{},
        }
    },
    mixins: [notificationMixin],
    props:['property_id'],
    async created() {
        await this.fetchPropertySpaceGroup();
        await this.fetchSpaceGroup();
    },
    methods: {
        async fetchPropertySpaceGroup() {
            try {
                let response = await api.get(
                    this,
                    api.PROPERTIES + this.property_id + "/space-groups"
                );
                this.spaceGroups = response.spaceGroups;
            } catch (err) {
                this.showMessageNotification({type: 'error', description: err});
            }
        },
        async fetchSpaceGroup() {
            try {
                this.previousSpaceGroupProfile = await api.get(
                    this,
                    api.APW_SETTING + 'properties/' + this.property_id,
                );
                if(this.previousSpaceGroupProfile?.id) {
                    this.form.spaceGroupProfile = this.spaceGroups.find(group => group.id == this.previousSpaceGroupProfile.unit_group_profile_id)
                }
            } catch (error) {
                this.showMessageNotification({type: 'error', description: err});
            }
        },
        async saveSetting(){
            let data;
            if(this.previousSpaceGroupProfile?.id){
                data = {
                    id: this.previousSpaceGroupProfile?.id,
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            }else{
                data = {
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            }
            try {
                await api.post(
                    this,
                    api.APW_SETTING + 'properties/' + this.property_id,
                    data
                );
                this.showMessageNotification({ type: 'success', description: 'Advance Reservation and Rental Space Grouping Profile has been updated successfully.' });
            } catch (err) {
                this.showMessageNotification({type: 'error', description: err});
            }
        }
    }
}
</script>